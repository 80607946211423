import { getConfig } from '../helpers';

const keyPageIds = getConfig('common.keyPageIds');

enum keyPages {
    homePage = keyPageIds.homePage,
    homepageVideo = keyPageIds.homePageVideo,
    productFinderPage = keyPageIds.productFinderPage,
    searchResultsPage = keyPageIds.searchResultsPage,
    datasetsPage = keyPageIds.datasetsPage,
    datasetPage = keyPageIds.datasetPage,
    datasetRATForm = keyPageIds.datasetRATForm,
    solutionRATForm = keyPageIds.solutionRATForm,
    datasetSampleDataDownload = keyPageIds.datasetSampleDataDownload,
    datasetDataDictionaryDownload = keyPageIds.datasetDataDictionaryDownload,
    solutionsPage = keyPageIds.solutionsPage,
    solutionPage = keyPageIds.solutionPage,
    // TODO Need to generate a keyPageId for the demo page(s)
    solutionDemoPage = keyPageIds.solutionDemoPage,
    vendorForm = keyPageIds.vendorForm,
    datasetRATFormSubmission = keyPageIds.datasetRATFormSubmission,
    solutionRATFormSubmission = keyPageIds.solutionRATFormSubmission,
    vendorFormSubmission = keyPageIds.vendorFormSubmission,
    profilePage = keyPageIds.profilePage,
    faq = keyPageIds.faq,
    newUserSignup = keyPageIds.newUserSignup,
    queryLibraryPage = keyPageIds.queryLibraryPage,
    datasetNotifyMe = keyPageIds.datasetNotifyMe,
    solutionNotifyMe = keyPageIds.solutionNotifyMe,
    datasetNotifyMeSubscribed = keyPageIds.datasetNotifyMeSubscribed,
    solutionNotifyMeSubscribed = keyPageIds.solutionNotifyMeSubscribed,
    queryBuilderPage = keyPageIds.queryBuilderPage,
    queryBuilderDownload= keyPageIds.queryBuilderDownload,
    queryBuilderNewQuery= keyPageIds.queryBuilderNewQuery,
    queryBuilderRATFormLoad= keyPageIds.queryBuilderRATFormLoad,
    queryBuilderRATSubmission = keyPageIds.queryBuilderRATSubmission,
    blueprintsPage = keyPageIds.blueprintsPage,
    blueprintPage = keyPageIds.blueprintPage,
    blueprintNotifyMe = keyPageIds.blueprintNotifyMe,
    blueprintNotifyMeSubscribed = keyPageIds.blueprintNotifyMeSubscribed,
    homeResultsPage = keyPageIds.homeResultsPage,
    myAccount = keyPageIds.myAccount,
    userProfileVersion = keyPageIds.userProfileVersion,
    queryLibraryProfilePage = keyPageIds.queryLibraryProfilePage,
    clientSubscriptions = keyPageIds.clientSubscriptions
}

export default keyPages;
