import update from 'react-addons-update';
import { API_INITIAL_DATASETS, API_FULL_DATASETS, API_OPENSEARCH_DATASETS } from '../actionTypes';
import { getText } from '../../helpers';

const initialDataSetState: DatasetsStoreState = {
    full: {},
    all: {},
    openSearch: {}
};

const globalLabel = getText('generic.globalLabel');

const getDelivery = (unmaskDelivery: number[], delivery: FilterCheckBoxItem[]) => {
    const marketSPGDestinationPlatform: Delivery[] = [];
    unmaskDelivery.forEach(KeySPGDestinationPlatform => {
        const deliveryFilter = delivery.find(filterDelivery => parseInt(filterDelivery.id as string, 0) === KeySPGDestinationPlatform);
        if (deliveryFilter) {
            marketSPGDestinationPlatform.push({
                SPGDestinationPlatform: deliveryFilter.name,
                KeySPGDestinationPlatform: parseInt(deliveryFilter.value as string, 0)
            });
        }
    });
    return marketSPGDestinationPlatform;
};

const getDeliveryPlatform = (unmaskDelivery: number[], delivery: FilterCheckBoxItem[]) => {
    const marketSPGDestinationPlatform: Delivery[] = [];
    unmaskDelivery.forEach(KeySPGDestinationPlatform => {
        const deliveryFilter = delivery.find(filterDelivery => parseInt(filterDelivery.id as string, 0) === KeySPGDestinationPlatform);
        if (deliveryFilter) {
            marketSPGDestinationPlatform.push({
                SPGDestinationPlatform: deliveryFilter.name,
                KeySPGDestinationPlatform: parseInt(deliveryFilter.value as string, 0),
                KeyPlatformService: deliveryFilter.keyPlatformService,
                MktplaceSolutionName: deliveryFilter.mktplaceSolutionName
            });
        }
    });
    return marketSPGDestinationPlatform;
};

const getAllIndustries = (industries: FilterCheckBoxItem[]) => {
    return industries
        .filter(industryFilter => parseInt(industryFilter.value as string, 0) > 0)
        .map(industryFilter => {
            const filter: MarketDatasetMIIndustry = {
                KeyMIIndustryTree: parseInt(industryFilter.value as string, 0),
                MIIndustryTree: { IndustryLongName: industryFilter.name }
            };
            return filter;
        });
};

const checkGlobalGeographic = (marketDatasetGeography: MarketDatasetGeography[], regions: FilterCheckBoxItem[]) => {
    const Global = regions.find(reg => reg.name === globalLabel);
    const keysArr: string[] = Global ? (Global.value as string).split(',') : [];
    const regionKeys = marketDatasetGeography ? marketDatasetGeography.map(reg => {
        return reg.KeyGeographyTree ? reg.KeyGeographyTree.toString() : undefined;
    }) : [];
    const missing = keysArr.filter(item => regionKeys.includes(item) === false);
    return missing.length > 0
        ? marketDatasetGeography
        : [{ KeyGeographyTree: 0, GeographyTree: { TreeID: 0, GlobalRegion: { GlobalRegion: globalLabel } } }];
};

const fetchDatasets = (response: DatasetResultAction) => {
    const { dataProfiles, filters } = response;

    const dataProfile = dataProfiles[0];

    dataProfile.marketDatasetMIIndustrys = dataProfiles[1].marketDatasetMIIndustrys;
    dataProfile.marketDatasetGeographys = dataProfiles[1].marketDatasetGeographys;
    dataProfile.marketDatasetLinks = dataProfiles[1].marketDatasetLinks;

    const unmaskDelivery = dataProfile.marketSPGDestinationPlatform;
    dataProfile.deliveryChannel = getDelivery(unmaskDelivery, filters.delivery);
    const unmaskDeliveryPlatform = dataProfile.marketSPGDeliveryPlatform;
    dataProfile.deliveryPlatform = getDeliveryPlatform(unmaskDeliveryPlatform, filters.allDeliveryPlatform);
    // Set Global if all Key present for geography
    dataProfile.marketDatasetGeographys = checkGlobalGeographic(dataProfile.marketDatasetGeographys, filters.regions);

    // When dataset has KeyMIIndustryTree == 0 i.e ALL need to show all Industry Labels from industry filters
    if (dataProfile.marketDatasetMIIndustrys && dataProfile.marketDatasetMIIndustrys.filter((industry: MarketDatasetMIIndustry) => industry.KeyMIIndustryTree === 0).length > 0) {
        dataProfile.marketDatasetMIIndustrys = getAllIndustries(filters.industries);
    }
    return dataProfile;
};

const fetchBundle = (response: DatasetResultAction) => {
    const { dataProfiles, filters } = response;
    const dataProfile = dataProfiles[0];
    const relatedDataProfile = dataProfiles[1];
    const relatedDatasets = dataProfiles[2];
    const countbundleDatasets = dataProfile && dataProfile.bundleDatasets ? dataProfile.bundleDatasets.length : 0;
    const countrelateddataProfiles = relatedDataProfile && relatedDataProfile.bundleDatasets ? relatedDataProfile.bundleDatasets.length : 0;
    if (countbundleDatasets && countrelateddataProfiles) {
        for (let i = 0; i < countbundleDatasets; i++) {
            const dataset =
                relatedDataProfile.bundleDatasets &&
                relatedDataProfile.bundleDatasets.find(item => dataProfile.bundleDatasets && item.id === dataProfile.bundleDatasets[i].id);
            if (dataset && dataProfile.bundleDatasets) {
                dataProfile.bundleDatasets[i].marketDatasetMIIndustrys = dataset.marketDatasetMIIndustrys;
                dataProfile.bundleDatasets[i].marketDatasetLinks = dataset.marketDatasetLinks;
                dataProfile.bundleDatasets[i].marketDatasetGeographys = checkGlobalGeographic(dataset.marketDatasetGeographys, filters.regions);

                const unmaskDelivery = dataProfile.bundleDatasets[i].marketSPGDestinationPlatform;
                dataProfile.bundleDatasets[i].deliveryChannel = getDelivery(unmaskDelivery, filters.delivery);
                const unmaskDeliveryPlatform = dataProfile.bundleDatasets[i].marketSPGDeliveryPlatform;
                dataProfile.bundleDatasets[i].deliveryPlatform = getDeliveryPlatform(unmaskDeliveryPlatform, filters.allDeliveryPlatform);
                // When dataset has KeyMIIndustryTree == 0 i.e ALL need to show all Industry Labels from industry filters
                if (
                    dataProfile.bundleDatasets[i].marketDatasetMIIndustrys.filter(
                        (industry: MarketDatasetMIIndustry) => industry.KeyMIIndustryTree === 0
                    ).length > 0
                ) {
                    dataProfile.bundleDatasets[i].marketDatasetMIIndustrys = getAllIndustries(filters.industries);
                }
            }
        }
    }
    dataProfile.marketDatasetDatasets = relatedDatasets.marketDatasetDatasets;
    return dataProfile;
};

// TODO - remove any
// eslint-disable-next-line
const reducer = (state: DatasetsStoreState = initialDataSetState, incomingAction: DatasetsResultAction | DatasetResultAction) => {
    const action = incomingAction;
    switch (action.type) {
        case API_INITIAL_DATASETS: {
            const actionObj = action as DatasetsResultAction;
            return update(state, { all: { response: { $set: actionObj.response }, time: { $set: actionObj.time } } });
        }
        case API_OPENSEARCH_DATASETS: {
            const actionObj = action as DatasetsResultAction;
            return update(state, { openSearch: { response: { $set: actionObj.response }, time: { $set: actionObj.time } } });
        }
        case API_FULL_DATASETS: {
            const actionObj = action as DatasetResultAction;
            let idAsString: string = actionObj.id;
            if (actionObj.dataProfiles !== undefined && actionObj.dataProfiles.length > 0) {
                const { dataProfiles } = actionObj;

                const dataProfile = dataProfiles[0];
                let dataProfileResponse;

                if (dataProfile.isBundle) {
                    dataProfileResponse = fetchBundle(actionObj);
                    idAsString += '-B';
                } else {
                    dataProfileResponse = fetchDatasets(actionObj);
                    idAsString += '-D';
                }

                return update(state, { full: { [idAsString]: { $set: { response: { ...dataProfileResponse, cachedTime: new Date() } } } } });
            }
            return state;
        }
        default:
            return state || initialDataSetState;
    }
};

export { reducer, initialDataSetState };
