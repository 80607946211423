import { addTask } from 'domain-task';
import { Dispatch } from 'react';
import { API_INITIAL_DATASETS, API_FULL_DATASETS, API_FILTERS, API_OPENSEARCH_DATASETS } from '../actionTypes';
import APIManager from '../../utilities/api-manager';
import { get, getConfig, dateDiff, getText } from '../../helpers';
import {
    mapDataBundleToDataProfileCard,
    mapDatasetToDataProfileCard,
    mapDatasetToDataProfile,
    mapDataBundleToDataProfile,
    mapDataBundleToRelatedDataset,
    mapDataBundleToIndustry,
    mapSearchResultToDataProfileCard
} from '../../utilities/data-mappers';

const apiDataRefreshTime = getConfig('redux.cacheRefreshTimes.long');

// get the intital datasets with only limited properties
const fetchInitialDataset = (): AppThunkAction<DatasetsResultAction> => (dispatch, getState) => {
    const { datasets } = getState();
    const cachedResponse = get(datasets, 'all');
    const hasExpired = cachedResponse && (!cachedResponse.time ? true : dateDiff(cachedResponse.time) > apiDataRefreshTime);
    if (hasExpired) {
        const isDefaultLanguage = getText('isDefaultLanguage'); // making it local as SSR needs to fetch this for each store
        const fetchTask = APIManager.getDataCatalog().then(response => {
            const datasetNames = !isDefaultLanguage && response[2] ? response[2] : [];
            const BundleNames = !isDefaultLanguage && response[3] ? response[3] : [];
            const datasetCards: DataProfileCard[] = response[0].map((item: APIDataset) => mapDatasetToDataProfileCard(item, datasetNames));
            const dataBundleCards: DataProfileCard[] = response[1].map((item: APIDataBundleCard) => mapDataBundleToDataProfileCard(item, BundleNames));
            dispatch({ response: [...datasetCards, ...dataBundleCards], time: new Date(), type: API_INITIAL_DATASETS });
        });
        addTask(fetchTask);
    }
};

// get the intital datasets with only limited properties
const fetchOpenSearchDataset = (): AppThunkAction<DatasetsResultAction> => (dispatch, getState) => {
    const { datasets } = getState();
    const cachedResponse = get(datasets, 'openSearch');
    const hasExpired = cachedResponse && (!cachedResponse.time ? true : dateDiff(cachedResponse.time) > apiDataRefreshTime);
    if (hasExpired) {
        const fetchTask = APIManager.search('', [], ['isType:Dataset OR isType:Bundle'])
            .then(res => {
                const dataProfileCards = get(res, 'data.hits.hits', []);
                // eslint-disable-next-line no-underscore-dangle
                const datasetprofileCards: DataProfileCard[] = dataProfileCards.map((item: Hit) => mapSearchResultToDataProfileCard(item._source));
                dispatch({ response: [...datasetprofileCards], time: new Date(), type: API_OPENSEARCH_DATASETS });
            })
            .catch(error => {
                if (!(error && error.message && error.message === 'Search canceled due to new request.')) {
                    console.warn(error);
                }
            });
        addTask(fetchTask);
    }
};

// eslint-disable-next-line
const FetchDatasetsOrBundle = (id: string, isBundle: boolean, filters: any) => {
    if (isBundle) {
        return APIManager.getBundleDataById(id).then(BatchRes => {
            const dataProfiles: DataProfile[] = [];
            if (BatchRes) {
                if (BatchRes.length > 0) dataProfiles.push(mapDataBundleToDataProfile(BatchRes[0]));
                if (BatchRes.length > 1) dataProfiles.push(mapDataBundleToIndustry(BatchRes[1]));
                if (BatchRes.length > 2) dataProfiles.push(mapDataBundleToRelatedDataset(BatchRes[2]));
            }
            return { dataProfiles, filters };
        });
    }

    return APIManager.getBatchDataSetDetailsById(id).then(BatchRes => {
        const dataProfiles: DataProfile[] = BatchRes.map(dataset => mapDatasetToDataProfile(dataset));
        return { dataProfiles, filters };
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dispatchFullDataset = (params: DatasetParams, state: AnonymousObject, dispatch: Dispatch<DatasetResultAction>) => {
    const { id, isBundle } = params;
    const { datasets, filters } = state;
    const cachedResponse = get(datasets, `full.${id}-${isBundle ? 'B' : 'D'}`, {});
    const hasExpired = cachedResponse && (!cachedResponse.response ? true : dateDiff(cachedResponse.response.cachedTime) > apiDataRefreshTime);

    if (!id || !cachedResponse || hasExpired) {
        const fetchTask = FetchDatasetsOrBundle(id, params.isBundle, filters).then(response => {
            dispatch({
                dataProfiles: response.dataProfiles,
                filters: response.filters,
                id: params.id,
                type: API_FULL_DATASETS
            });
        });
        addTask(fetchTask);
    }
};

// gets each full dataset
const fetchFullDatasetwithfilters = (params: DatasetParams): AppThunkAction<DatasetResultAction|FiltersResultAction> => async (dispatch, getState) => {
    // need to pass filters as we need Industries and deleviry filted in full dataset to be shown
    // As filter is from diffrent reducer So, this is a way we can pass to other reducer
    const { filters } = getState();
    if (filters.time !== '') {
        dispatchFullDataset(params, getState(), dispatch);
    } else {
        const fetchTask = APIManager.getBatchFilters().then(response => {
            dispatch({ response, time: new Date(), type: API_FILTERS });
            dispatchFullDataset(params, getState(), dispatch);
        });
        addTask(fetchTask);
    }
};

export { fetchInitialDataset, fetchFullDatasetwithfilters, fetchOpenSearchDataset };
