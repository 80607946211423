import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { AxiosResponse } from 'axios';
import { USER_LOGGIN, USER_PROFILE, CLEAR_USER_PROFILE, TABLEAU_SESSION_CREATED, USER_PREFERENCE, UPDATE_USER_PREFERENCE, UPDATE_USER_PREFERENCE_MANUALLY } from '../actionTypes';
import APIManager from '../../utilities/api-manager';
import spgVars from '../../utilities/spg-variables';
import { get, getConfig, getPageTextsSection } from '../../helpers';
import keyPageIds from '../../utilities/key-pages';

const actionCreator = actionCreatorFactory();

const toggleLogInStatus = actionCreator<boolean>(USER_LOGGIN);

const spgVariables = spgVars();
const Window = !spgVariables.IS_SERVER_REQUEST ? window as AnonymousObject : { dataLayer: [] };
if (Window) Window.dataLayer = Window.dataLayer || [];

const toggleSessionCreate = actionCreator<boolean>(TABLEAU_SESSION_CREATED);

const actionCreatorAsync = asyncFactory<UserStoreState>(actionCreator);
let dataUserProfileVersion = getPageTextsSection('', []);
const pageText = getConfig('pagetext.userProfileVersion');
function setGTMdatalayer(userInfo: UserInfo) {
    if (Window) {
        Window.dataLayer.push({
            KeyOnlineUser: userInfo.KeyOnlineUser,
            SubscriberAccount: userInfo.SubscriberAccount,
            CompanyType: userInfo.CompanyType,
            PrimaryJobFunction: userInfo.PrimaryJobFunction,
            UserWebpartProfile: userInfo.UserWebpartProfile
        });
    }
}

const addRemoveBookmarks = (key: string, kou: string, paramsList: BookmarkParams[], bookmarks: Bookmarks) => {
    paramsList.forEach(params => {
        const { action, type, bookmark } = params;
        if (action === 'add') {
            if (!(type in bookmarks)) bookmarks[type as keyof Bookmarks] = [];
            // verify is already added to bookmarks by other browser
            if (type === 'datasets' && bookmarks.datasets.filter(d => d.keyId === params.bookmark.keyId.toString()).length === 0) bookmarks.datasets.push(bookmark);
            if (type === 'solutions' && bookmarks.solutions.filter(d => d.keyId === params.bookmark.keyId).length === 0) bookmarks.solutions.push(bookmark);
            if (type === 'blueprints' && bookmarks.blueprints.filter(d => d.keyId === params.bookmark.keyId).length === 0) bookmarks.blueprints.push(bookmark);
            if (
                (type === 'queries' && !bookmarks.queries) ||
                (type === 'queries' && bookmarks.queries && bookmarks.queries.filter(d => d.keyId === params.bookmark.keyId.toString()).length === 0)
            ) {
                if (bookmarks.queries === undefined) bookmarks.queries = [];
                bookmarks.queries.push(bookmark);
            }
        } else if (action === 'remove') {
            if (type === 'datasets') bookmarks.datasets = bookmarks.datasets.filter(d => d.keyId !== bookmark.keyId.toString());
            if (type === 'solutions') bookmarks.solutions = bookmarks.solutions.filter(d => d.keyId !== bookmark.keyId);
            if (type === 'blueprints') bookmarks.blueprints = bookmarks.blueprints.filter(d => d.keyId !== bookmark.keyId);
            if (type === 'queries') bookmarks.queries = bookmarks.queries.filter(d => d.keyId !== bookmark.keyId.toString());
        }
    });
    return APIManager.updateUserPreferance(kou, bookmarks).then(createResponse => {
        // update redux back with the changes
        paramsList.forEach(params => {
            if (params.onSuccess) {
                params.onSuccess();
            }
        });
        return APIManager.getBookMarkPreferencesCMS(kou).then(response => {
            const newBookmarks: any = response.data.RequestJson || { datasets: [], solutions: [], queries: [], blueprints: [] };
            const bookmarkPrefer = { key, bookMarks: newBookmarks, bookmarksLoaded: true };
            localStorage.setItem('bookmarkPreference', JSON.stringify(bookmarkPrefer));
            return bookmarkPrefer;
        });
    });
};

const fetchUserPreference = actionCreatorAsync<null, BookmarkPreference>(USER_PREFERENCE, (params, dispatch, getState) => {
    const state = getState();
    const bookmarkPreference = get(state, 'user.bookmarkPreference');
    const key = getConfig('common.keyBookmark');

    return APIManager.getPageTexts().then((pageResponse: AxiosResponse<PageSections[]>) => {
        let pageTextsData;
        if (pageResponse.data) {
            pageTextsData = pageResponse.data;
        }
        pageTextsData = pageTextsData && pageTextsData.filter(x => x.KeyOnlinePage === keyPageIds.userProfileVersion);
        if (pageTextsData) {
            dataUserProfileVersion = getPageTextsSection(pageText.headerSection, pageTextsData);
        }
        return APIManager.getUserInfo(dataUserProfileVersion.title).then(userInfo => {
            if (userInfo && userInfo.data) {
                const kou = userInfo.data.KeyOnlineUser;
                if (key && kou) {
                    return APIManager.getBookMarkPreferencesCMS(kou).then(response => {
                        return {
                            key,
                            // Testing bookmarks, actual on 28.08.2024
                            // bookMarks: { datasets: [{ keyId: '1724327246', name: 'short-sentiment-factors', created: '' }], solutions: [{ keyId: '03f3c047-dcac-4fa7-a1ef-fb48a4d9b75d', name: 's-p-global-marketplace-catalog-api', created: '' }], blueprints: [{ keyId: '8de48fc7-b79c-4fca-9c31-485df81af83f', name: 'python-automation', created: '' }], queries: [{ keyId: '612', name: 'API Request To Return The Top 10 Professionals Of The Passed In Identifier', created: '' }] },
                            bookMarks: response.data.RequestJson || { datasets: [], solutions: [], queries: [], blueprints: [] },
                            bookmarksLoaded: true
                        };
                    });
                }
            }
            return bookmarkPreference;
        });
    });
});

const updateUserPreference = actionCreatorAsync<BookmarkParams[], BookmarkPreference>(UPDATE_USER_PREFERENCE, (params, dispatch, getState) => {
    const state = getState();
    const user = get(state, 'user');
    const { bookmarkPreference, userInfo } = user;
    const { key } = bookmarkPreference;
    if (user.isLoggedIn && userInfo) {
        const kou = userInfo.KeyOnlineUser;
        // get latest bookmarks before updating it
        return APIManager.getBookMarkPreferencesCMS(kou).then(response => {
            const bookmarks: any = response.data.RequestJson;
            return addRemoveBookmarks(key, kou, params, bookmarks);
        });
    }
    // Return the existing bookmarkPreference if the user info is not available
    return bookmarkPreference;
});

const updateUserPreferenceManually = actionCreatorAsync<BookmarkPreference, BookmarkPreference>(UPDATE_USER_PREFERENCE_MANUALLY, params => {
    return params;
});

// get the intital datasets with only limited properties
const fetchUserProfile = actionCreatorAsync<UserProfileParams, { userInfo: UserInfo; profileItems: UserProfileData }>(
    USER_PROFILE,
    (params, dispatch, getState) => {
        const state = getState();
        const user = get(state, 'user');
        const { refreshUserInfo, onSuccess } = params;
        if (user.isLoggedIn && (!user.userInfo || refreshUserInfo)) {
            if (refreshUserInfo) {
                return APIManager.getPageTexts().then((pageResponse: AxiosResponse<PageSections[]>) => {
                    let pageTextsData;
                    if (pageResponse.data) {
                        pageTextsData = pageResponse.data;
                    }
                    pageTextsData = pageTextsData && pageTextsData.filter(x => x.KeyOnlinePage === keyPageIds.userProfileVersion);
                    if (pageTextsData) {
                        dataUserProfileVersion = getPageTextsSection(pageText.headerSection, pageTextsData);
                    }
                    return APIManager.invalidateUserInfo().then(() => {
                        return APIManager.getUserInfo(dataUserProfileVersion.title).then(response => {
                            if (response) {
                                if (onSuccess) {
                                    onSuccess(response.data);
                                }
                                setGTMdatalayer(response.data);
                                const { Country, Email } = response.data;
                                return APIManager.getAllProfilePageItems(Country, Email).then(profileItems => {
                                    const { CompanyTypeList, rolesList, RegistrationFormatItems } = profileItems.data;
                                    return { ...response.data, ProfileItems: { CompanyTypeList, rolesList, RegistrationFormatItems } };
                                });
                            }
                            return user;
                        });
                    });
                });
            }
            return APIManager.getPageTexts().then((pageResponse: AxiosResponse<PageSections[]>) => {
                let pageTextsData;
                if (pageResponse.data) {
                    pageTextsData = pageResponse.data;
                }
                pageTextsData = pageTextsData && pageTextsData.filter(x => x.KeyOnlinePage === keyPageIds.userProfileVersion);
                if (pageTextsData) {
                    dataUserProfileVersion = getPageTextsSection(pageText.headerSection, pageTextsData);
                }
                return APIManager.getUserInfo(dataUserProfileVersion.title).then(response => {
                    if (response) {
                        const { Country, Email } = response.data;
                        if (onSuccess) {
                            onSuccess(response.data);
                        }
                        setGTMdatalayer(response.data);
                        return APIManager.getAllProfilePageItems(Country, Email).then(profileItems => {
                            const { CompanyTypeList, rolesList, RegistrationFormatItems } = profileItems.data;
                            return { ...response.data, ProfileItems: { CompanyTypeList, rolesList, RegistrationFormatItems } };
                        });
                    }
                    return user;
                });
            });
        }

        if (onSuccess) {
            onSuccess(user.userInfo);
        }
        if (user.userInfo) setGTMdatalayer(user.userInfo);

        return user.userInfo;
    }
);

const clearUserProfile = actionCreator(CLEAR_USER_PROFILE);

export { toggleLogInStatus, fetchUserProfile, clearUserProfile, toggleSessionCreate, fetchUserPreference, updateUserPreference, updateUserPreferenceManually };
